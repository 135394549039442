// This file is generated by the openedx/frontend-platform's "intl-import.js" script.
//
// Refer to the i18n documents in https://docs.openedx.org/en/latest/developers/references/i18n.html to update
// the file and use the Micro-frontend i18n pattern in new repositories.
//

import messagesOfArLanguage from './ar.json';
import messagesOfDaLanguage from './da.json';
import messagesOfDeLanguage from './de.json';
import messagesOfDeDeLanguage from './de_DE.json';
import messagesOfElLanguage from './el.json';
import messagesOfEs419Language from './es_419.json';
import messagesOfEsEsLanguage from './es_ES.json';
import messagesOfFaLanguage from './fa.json';
import messagesOfFrCaLanguage from './fr_CA.json';
import messagesOfHeLanguage from './he.json';
import messagesOfHiLanguage from './hi.json';
import messagesOfIdLanguage from './id.json';
import messagesOfItItLanguage from './it_IT.json';
import messagesOfPtBrLanguage from './pt_BR.json';
import messagesOfPtPtLanguage from './pt_PT.json';
import messagesOfRuLanguage from './ru.json';
import messagesOfSwLanguage from './sw.json';
import messagesOfTeLanguage from './te.json';
import messagesOfThLanguage from './th.json';
import messagesOfTrTrLanguage from './tr_TR.json';
import messagesOfUkLanguage from './uk.json';
import messagesOfViLanguage from './vi.json';
import messagesOfZhCnLanguage from './zh_CN.json';

export default {
  'ar': messagesOfArLanguage,
  'zh-cn': messagesOfZhCnLanguage,
  'vi': messagesOfViLanguage,
  'uk': messagesOfUkLanguage,
  'tr-tr': messagesOfTrTrLanguage,
  'th': messagesOfThLanguage,
  'te': messagesOfTeLanguage,
  'sw': messagesOfSwLanguage,
  'ru': messagesOfRuLanguage,
  'pt-pt': messagesOfPtPtLanguage,
  'pt-br': messagesOfPtBrLanguage,
  'it-it': messagesOfItItLanguage,
  'id': messagesOfIdLanguage,
  'hi': messagesOfHiLanguage,
  'he': messagesOfHeLanguage,
  'fr-ca': messagesOfFrCaLanguage,
  'fa': messagesOfFaLanguage,
  'es-es': messagesOfEsEsLanguage,
  'es-419': messagesOfEs419Language,
  'el': messagesOfElLanguage,
  'de-de': messagesOfDeDeLanguage,
  'de': messagesOfDeLanguage,
  'da': messagesOfDaLanguage,
};
