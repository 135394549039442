import React, { useState, useEffect } from 'react';

import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Alert } from '@blend-ed/blendx-ui';
import PropTypes from 'prop-types';

import messages from './messages';
import { LOGIN_PAGE, REGISTER_PAGE } from '../data/constants';

const ThirdPartyAuthAlert = (props) => {
  const { formatMessage } = useIntl();
  const { currentProvider, referrer } = props;
  const [showAlert, setShowAlert] = useState(true);
  const platformName = getConfig().SITE_NAME;
  let message;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (referrer === LOGIN_PAGE) {
    message = formatMessage(messages['login.third.party.auth.account.not.linked'], { currentProvider, platformName });
  } else {
    message = formatMessage(messages['register.third.party.auth.account.not.linked'], { currentProvider, platformName });
  }

  if (!currentProvider || !showAlert) {
    return null;
  }

  return (
    <>
      <Alert icon={referrer === REGISTER_PAGE ? 'checkbox-circle' : 'error-warning'} id="tpa-alert" className={referrer === REGISTER_PAGE ? 'alert-outline-success mt-n2' : 'alert-outline-warning mt-n2'}>
        {referrer === REGISTER_PAGE ? (
          <Alert.Heading>{formatMessage(messages['tpa.alert.heading'])}</Alert.Heading>
        ) : null}
        <p>{message}</p>
      </Alert>
      {referrer === REGISTER_PAGE ? (
        <h5 className="text-center">{formatMessage(messages['registration.using.tpa.form.heading'])}</h5>
      ) : null}
    </>
  );
};

ThirdPartyAuthAlert.defaultProps = {
  currentProvider: '',
  referrer: LOGIN_PAGE,
};

ThirdPartyAuthAlert.propTypes = {
  currentProvider: PropTypes.string,
  referrer: PropTypes.string,
};

export default ThirdPartyAuthAlert;
