import React, { useState } from 'react';
import backGroundImg from './cta-bg.svg';
import logo from './logo.svg';
import { Button, Image } from '@blend-ed/blendx-ui';
import { connect } from 'react-redux';
import { getConfig } from '@edx/frontend-platform';
import {
    loginRequest,
    loginRequestSuccess,
} from '../login/data/actions';
import {
    RedirectLogistration,
} from '../common-components';
import { Link } from 'react-router-dom';

const SandboxLogin = ({ loginRequest, loginRequestSuccess, loginResult }) => {
    const [redirectUrl, setRedirectUrl] = useState(null);
    const handleSandboxLogin = (role) => {
        const sandboxCredentials = {
            student: {
                emailOrUsername: getConfig().STUDENT_USERNAME,
                password: getConfig().STUDENT_PASSWORD,
            },
            staff: {
                emailOrUsername: getConfig().STAFF_USERNAME,
                password: getConfig().STAFF_PASSWORD,
            },
        };

        if (role === 'student') {
            setRedirectUrl(getConfig().DASHBOARD_URL);
        } else {
            setRedirectUrl(getConfig().STAFF_DASHBOARD_URL);
        }

        const credentials = sandboxCredentials[role];
        loginRequest({
            email_or_username: credentials.emailOrUsername,
            password: credentials.password,
        });
    };

    return (
        <div className="sandbox-login" >
            <RedirectLogistration
                success={loginResult.success}
                redirectUrl={redirectUrl}
            />
            <div className="sandbox-login__background" style={{
                backgroundImage: `url(${backGroundImg})`,
                backgroundSize: 'auto',
                backgroundPosition: 'center',
            }}>
                <Link to={"https://blend-ed.com"} className="sandbox-login__navbar">
                    <Image src={logo} alt="Blend-ed Logo" className='mt-n1' />
                </Link>
                <div className="sandbox-login__header" >
                    <h1 className="sandbox-login__title">Blend-ed Sandbox</h1>
                    <p className="sandbox-login__description">Discover the platform both as a learner and as an organisation admin. This site is automatically wiped clean periodically to provide a better experience for all visitors.</p>
                </div>
            </div>

            <div className="sandbox-login__body">
                <div className="sandbox-login__body-buttons">
                    <Button className="sandbox-login__body-button" onClick={() => handleSandboxLogin('student')} variant="outline-gray" size="lg">Login as a Student</Button>
                    <Button className="sandbox-login__body-button" onClick={() => handleSandboxLogin('staff')} variant="outline-gray" size="lg">Login as a Admin</Button>
                </div>
                <p className="sandbox-login__body-note"> Note: To get instructor and mentor experience please use larger screens</p>

            </div>
        </div>
    )
};

const mapStateToProps = state => {
    const loginPageState = state.login;
    return {
        loginResult: loginPageState.loginResult,
    };
};

export default connect(mapStateToProps, { loginRequest, loginRequestSuccess })(SandboxLogin);
